/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormLabel, TextField, CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";

import { api, instanceNew } from "../../shared/services/api";
import LoadingButton from "../../shared/Button/LoadingButton";
import { useLayout } from "../../hooks/auth";
import Cookies from "js-cookie";
import ModalTermosDeUso from "../../shared/ModalDeTermos";
import { Form } from "../../shared";

import "react-toastify/dist/index";
import "./styles.scss";

type windowSmartlook = Window & { smartlook?: any };

interface ILoginForm {
  jwtusername: string;
  jwtpassword: string;
  PORTALWEB: number;
}

export default function Login() {
  const { setLayout, imageLoginURL, colorPrimary, colorFontPrimrary } =
    useLayout();
  const [usuarioValue, setUsuarioValue] = useState("");
  const [senhaValue, setSenhaValue] = useState("");
  const [auth, setAuth] = useState(false);
  const [error, setError] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const [inputAuth, setInputAuth] = useState(true);
  const [inputNewPasswordState, setInputNewPasswordState] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [idOption, setIdOption] = useState(0);
  const [NucCodigo, setNucCodigo] = useState(0);
  const [companyDocument, setCompanyDocument] = useState("");
  const [valueOption, setValueOption] = useState(0);
  const [valueOptionName, setValueOptionName] = useState("");
  const history = useHistory();
  const [valueAutoComplere, setValueAutoComplere] = useState<string | null>(
    empresas[0]
  );
  const [inputAutoCompleteValue, setInputAutoCompleteValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingGetConfiguration, setLoadingGetConfiguration] = useState(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const smartlookAccess = Cookies.get("Smartlook:consentAPI");
    setAccept(smartlookAccess === "true" ? true : false);
  }, []);

  useEffect(() => {
    if (!imageLoginURL) {
      setLoadingGetConfiguration(true);
    }

    localStorage.clear();

    async function loadSchedule() {
      const localRef = window.location.href;
      const teste = localRef.toString();
      const testeA = teste.replace("https://", "");
      const testeB = testeA.replace("http://", "");
      const testeC = testeB.replace("/", "");
      localStorage.setItem("LinkGet", testeC);
      localStorage.setItem("Token_Key", "");
      localStorage.setItem("tokenGet", "");
      localStorage.setItem("EMP_NAME", "");
      localStorage.setItem("EMP_DOCUMENT", "");
      let response;

      try {
        response = await axios.get(
          "https://apigestao.prosesmt.com.br/api/clientes/ambiente",
          {
            params: { host: testeC },
          }
        );
      } catch (err) {
        response = await axios.get(
          `https://v2ml9g04bl.execute-api.us-east-1.amazonaws.com/default/lambda-data-clients`,
          {
            params: {
              host: testeC,
            },
          }
        );
      }
      setLayout(response.data);
      setLoadingGetConfiguration(false);
      // try {
      //   response = await axios.get(
      //     `https://v2ml9g04bl.execute-api.us-east-1.amazonaws.com/default/lambda-data-clients`,
      //     { params: { host: testeC } }
      //   );
      // } catch (err) {}
    }

    loadSchedule();
  }, [imageLoginURL, setLayout]);

  const handleUsuarioValue = (event) => {
    const local = localStorage.getItem("LinkGet");
    var user = event.target.value;
    if (
      local === "agenda.healthtotal.com.br" ||
      local === "healthteste.prosesmt.com.br" ||
      local === "facop.prosesmt.com.br"
    ) {
      user = "ht." + user;
    }
    setUsuarioValue(user);
  };
  const handleSenhaValue = (event) => {
    setSenhaValue(event.target.value);
  };

  const handleNewSenha = () => {
    setInputNewPasswordState(false);
    setAuth(true);
    setInputAuth(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const novaSenha = async () => {
    setLoading(true);

    if (usuarioValue.length === 0) {
      setError(true);
      setLoading(false);
      return;
    }
    const linkGet = localStorage.getItem("LinkGet");
    const URLEMAIL = linkGet.split("#")[0] + "/redefinirSenha/";

    const dataValues = {
      USULOGIN: usuarioValue,
      URLEMAIL,
    };

    try {
      const res = await instanceNew.post("/users/esqueciSenha", dataValues);
      toast.success(res.data.message);
      setInputNewPasswordState(true);
      setAuth(false);
      setInputAuth(true);
    } catch {
      toast.error("Email não enviado, entre em contato com o suporte");
    }

    setLoading(false);
  };

  const login = async () => {
    try {
      if (valueAutoComplere.length !== 0) {
        const objectValues = {
          jwtusername: usuarioValue,
          jwtpassword: senhaValue,
          CLICODIGO: valueOption,
          EMPCODIGO: idOption,
          NUCCODIGO: NucCodigo,
          PORTALWEB: 1,
        };
        setLoadingSelect(true);
        const response = await instanceNew.post("/auth/login", objectValues);
        localStorage.setItem("Token_Key", response.data.token);
        document.cookie = `token=${response.data.token}`;
        localStorage.setItem("EMP_NAME", valueOptionName);
        localStorage.setItem("EMP_DOCUMENT", companyDocument);
        localStorage.setItem("EMPCODIGO", idOption.toString());

        const req = await api();

        req.get(`/agenda/contratos/aceites`).then((res) => {
          if (res.data.RETORNO === "0") {
            history.push("/termos");
            return;
          } else {
            history.push(`/pages`);
            return;
          }
        });
        return;
      }
    } catch {
      setLoadingSelect(false);
    }
  };

  const verfAuth = async (data: ILoginForm) => {
    try {
      const response = await instanceNew.post("/auth/login", data);
      localStorage.setItem("tokenGet", response.data.token);
      setLoading(true);
      if (response.data.token.length !== 0) {
        setAuth(true);
        setInputAuth(false);
        setInputNewPasswordState(true);
        const req = await api();
        const responseGet = await req.get("/users/company", {
          headers: { Authorization: `Bearer ${response.data.token}` },
        });
        setEmpresas(responseGet.data);
        userConsent(accept);
      }
      return;
    } catch (error) {
      setLoading(false);

      if (usuarioValue || senhaValue.length !== 0) {
        setErrorPass(true);
        setError(false);
        return;
      }
      setError(true);
    } finally {
    }
  };

  const userConsent = (accept: boolean) => {
    Cookies.set("Smartlook:consentAPI", accept.toString());
    const consentAPIText =
      "O usuário permitiu ter seus dados registrados pelo aplicativo do ProSESMT";
    const consentIPText =
      "O usuário permitiu ter seu ip registrado pelo aplicativo do ProSESMT";
    const w = window as windowSmartlook;
    if (w || w.smartlook) {
      w.smartlook("consentAPI", accept ? consentAPIText : false);
      w.smartlook("consentIP", accept ? consentIPText : false);
    }
  };

  return (
    <>
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f3f3f3"
      >
        <Paper
          style={{
            width: 450,
            padding: "24px 48px 32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <Form
            onSubmit={() => {
              if (!accept) {
                toast.warning(
                  "Você deve confirmar que concorda com os termos de uso"
                );
                return;
              }
              setLoading(true);
              verfAuth({
                jwtusername: usuarioValue,
                jwtpassword: senhaValue,
                PORTALWEB: 1,
              });
            }}
            buttons={() => (
              <div
                style={{
                  maxWidth: 848,
                  width: "100%",
                }}
              >
                {inputAuth && inputNewPasswordState && (
                  <>
                    <div style={{ margin: "10px 0 -15px 10px" }}>
                      <FormControlLabel
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        label={
                          <p>
                            Concordo com os{" "}
                            <a
                               href="https://prosesmt.com.br/site/termos-de-uso/"
                               style={{ fontSize: 16 }}
                               target="_blank"
                               rel="noreferrer"
                            >
                              termos de uso
                            </a>
                          </p>
                        }
                        control={
                          <Checkbox
                            checked={accept}
                            onChange={(e) => setAccept(e.target.checked)}
                            color="primary"
                          />
                        }
                      />
                    </div>
                    <LoadingButton
                      variant="contained"
                      color={colorPrimary}
                      style={{
                        width: "100%",
                        height: "50px",
                        marginTop: 25,
                        display: "flex",
                        backgroundColor: colorPrimary,
                        color: colorFontPrimrary,
                      }}
                      loading={loading}
                      onlyLoader
                      size="large"
                      type="submit"
                    >
                      Próximo
                    </LoadingButton>

                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      marginTop="25px"
                    >
                      <a href="# " onClick={handleNewSenha}>
                        Esqueceu sua senha ?
                      </a>
                    </Box>
                  </>
                )}

                {!inputAuth && inputNewPasswordState && (
                  <LoadingButton
                    variant="contained"
                    // color={colorPrimary}
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: 25,
                      backgroundColor: colorPrimary,
                      color: colorFontPrimrary,
                    }}
                    size="large"
                    loading={loadingSelect}
                    onlyLoader
                    onClick={login}
                  >
                    Entrar
                  </LoadingButton>
                )}

                {!inputNewPasswordState && (
                  <LoadingButton
                    variant="contained"
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: 25,
                      backgroundColor: colorPrimary,
                      color: colorFontPrimrary,
                    }}
                    size="large"
                    loading={loading}
                    onClick={novaSenha}
                  >
                    Enviar
                  </LoadingButton>
                )}
              </div>
            )}
            schema={[
              {
                content: [
                  {
                    lg: 12,
                    name: "",
                    type: "custom",
                    customComponent: () => (
                      <ModalTermosDeUso
                        openModal={openModal}
                        onClose={handleCloseModal}
                      />
                    ),
                  },
                  {
                    lg: 12,
                    name: "jwtusername",
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <>
                        <Box
                          width="100%"
                          height="63px"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {loadingGetConfiguration ? (
                            <CircularProgress />
                          ) : (
                            <img
                              src={imageLoginURL}
                              style={{ maxWidth: "354px", maxHeight: "99px" }}
                              alt="logo"
                            />
                          )}
                        </Box>
                        <Divider
                          color="primary"
                          style={{
                            width: "calc(100% + 48px + 48px)",
                            marginLeft: "-48px",
                            marginBottom: "25px",
                            marginTop: "20px",
                          }}
                        />
                        <FormLabel
                          component="legend"
                          style={{
                            fontSize: "1.10rem",
                            fontWeight: "bold",
                            color: "primary",
                            marginBottom: "25px",
                          }}
                        >
                          {inputNewPasswordState
                            ? "Gestão de Agendamento"
                            : "Esqueci minha senha"}
                        </FormLabel>
                        <TextField
                          id="standard-number"
                          label="Usuário"
                          type="email"
                          error={error || errorPass}
                          autoComplete="off"
                          inputProps={{
                            spellCheck: "false",
                          }}
                          helperText={
                            error
                              ? "Campo Obrigatório"
                              : errorPass
                              ? "Usuario ou senha incorretos"
                              : ""
                          }
                          onChange={handleUsuarioValue}
                          style={{
                            marginBottom: 18,
                            width: "100%",
                            display: auth ? "none" : "flex",
                          }}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    lg: 12,
                    name: "jwtpassword",
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <>
                        <TextField
                          id="standard-number"
                          label="Senha"
                          type="password"
                          error={error || errorPass}
                          helperText={error ? "Campo Obrigatório" : ""}
                          onChange={handleSenhaValue}
                          style={{
                            marginBottom: 0,
                            width: "100%",
                            display: auth ? "none" : "flex",
                          }}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    lg: 12,
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <>
                        <TextField
                          id="standard-number"
                          label="Usuário"
                          type="email"
                          error={error}
                          helperText={error ? "Campo Obrigatório" : ""}
                          value={usuarioValue}
                          onChange={handleUsuarioValue}
                          style={{
                            marginBottom: 0,
                            width: "100%",
                            display: inputNewPasswordState ? "none" : "flex",
                          }}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    lg: 12,
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <>
                        <Autocomplete
                          style={{ display: inputAuth ? "none" : "flex" }}
                          id="country-select-demo"
                          value={valueAutoComplere}
                          onChange={(event: any, newValue: any | null) => {
                            setValueAutoComplere(newValue);
                            setIdOption(newValue.CompanyId);
                            setValueOption(newValue.ClientId);
                            setValueOptionName(newValue.CompanyName);
                            setNucCodigo(newValue.CoreId);
                            setCompanyDocument(newValue.CompanyDocument);
                          }}
                          inputValue={inputAutoCompleteValue}
                          onInputChange={(event, newInputValue) => {
                            setInputAutoCompleteValue(newInputValue);
                          }}
                          options={empresas as CountryType[]}
                          autoHighlight
                          getOptionLabel={(option) =>
                            `${option.CompanyName} - ${option.CompanyDocument}`
                          }
                          renderOption={(option) => (
                            <div>
                              <span>
                                {option.CompanyDocument + " - "}
                                {option.CompanyName}
                              </span>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecione a empresa"
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off", // disable autocomplete and autofill use off in text
                              }}
                            />
                          )}
                        />
                      </>
                    ),
                  },
                ],
              },
            ]}
          />
        </Paper>
      </Box>
    </>
  );
}

interface CountryType {
  CompanyId: string;
  CompanyName: string;
  CompanyDocument: string;
  ClientId: number;
}
