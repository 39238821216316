import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import jwt from "jsonwebtoken";
import Cookies from 'js-cookie';

import Agendamento from '../../images/agendamento.png'
import ConsultaAgendamento from '../../images/consulta-agendamento.png'
import Epi from '../../images/epi.png'
import Estoque from '../../images/estoque.png'
import EstoqueSvg from '../../images/estoque.svg'
import EpiSvg from '../../images/epi.svg'
import ConsultaAgendamentoSvg from '../../images/consultaAgendamento.svg'
import AgendamentoSvg from '../../images/agendamento.svg'

import { api } from "../../shared/services/api";
import TransitionsModal from "../../shared/modalAgenda";
import TransitionsModalSol from "../../shared/modalSol";
import './styles.scss'

export default function Inicio() {

    const history = useHistory();
    const [open, setOpen] = React.useState(false)
    const [openModalSol, setOpenModalSol] = React.useState(false)
    const [visible, setVisible] = React.useState(true)
    const [visibleSocial, setvisibleSocial] = React.useState(true)

    const [visibleFinanceiro, setvisibleFinanceiro] = React.useState(true)
    const [visibleSolicitacoes, setvisibleSolicitacoes] = React.useState(true)

    useEffect(() => {
      const accept = Cookies.get("Smartlook:consentAPI");
      if (accept) {
        type windowSmartlook = Window & { smartlook?: any };
        const w = window as windowSmartlook;
        const decoded: any = jwt.decode(localStorage.getItem("tokenGet"));
        const { UserName, UserEmail, username } = decoded;
        w.smartlook("identify", UserName, {
          "name": UserName,
          "email": UserEmail,
          "login": username
        })
      } else {

      }
    }, [])

    useEffect(() => {
        async function validaUsuario() {
            const req = await api();
            req.get('/agenda/acessos').then((response) => {
                if (response.data.AGENDAMENTO === '0') {
                    setVisible(false)
                }

                if (response.data.ESOCIAL === '0') {
                    setvisibleSocial(false)
                }

                if (response.data.FINANCEIRO === '0') {
                    setvisibleFinanceiro(false)
                }

                if (response.data.SOLICITACOES === '0') {
                    setvisibleSolicitacoes(false)
                }
            })
        }
        validaUsuario();
    }, []);

    const handleOpenModal = () => {
        setOpen(true)
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    const handleCloseModalSol = () => {
        setOpenModalSol(false);
    };

    const data = [
        {
            nome: 'Novo Agendamento',
            desc: 'Agende exame Admissional, Periódico, Demissional, Retorno ao Trabalho ou Mudança de Risco de seus funcionários',
            img: Agendamento,
            svg: AgendamentoSvg,
            redirect: () => {
                handleOpenModal()
            },
            visible: visible,
        },
        {
            nome: 'Ver Agendamentos',
            desc: 'Consulte os agendamentos realizados',
            img: ConsultaAgendamento,
            svg: ConsultaAgendamentoSvg,
            redirect: () => { history.push('/pages/VerAgendamentos') },
            visible: visible,
        },
        {
            nome: 'Exames Realizados',
            desc: 'Consulte os exames realizados pelos funcionários de sua empresa',
            img: Epi,
            svg: EpiSvg,
            redirect: () => { history.push('/pages/examesRealizados') },
            visible: visible,
        },
        {
            nome: 'Exames Pendentes',
            desc: 'Consulte os exames vencidos dos funcionários de sua empresa',
            img: Estoque,
            svg: EstoqueSvg,
            redirect: () => { history.push('/pages/examesPendentes') },
            visible: visible,
        },
        {
            nome: 'Documentos',
            desc: 'Consulte documentos como PPRA, PCMSO e Laudo de Insalubridade e Periculosidade',
            img: Epi,
            svg: EpiSvg,
            redirect: () => { history.push('/pages/Documentos') },
            visible: visible,
        },
        {
            nome: 'Gerenciar Solicitações',
            desc: 'Consulte solicitações',
            img: Estoque,
            svg: EstoqueSvg,
            redirect: () => { history.push('/pages/GerenciarSolicitações') },
            visible: visible && visibleSolicitacoes,
        },
        {
            nome: 'Nova Solicitação',
            desc: 'Crie novas Solicitações',
            img: Agendamento,
            svg: AgendamentoSvg,
            redirect: () => { setOpenModalSol(true) },
            visible: visible && visibleSolicitacoes,
        },
        {
            nome: 'Central de Notificações',
            desc: 'Consulte as notificações geradas pela clínica',
            img: ConsultaAgendamento,
            svg: ConsultaAgendamentoSvg,
            redirect: () => { history.push('/pages/centralNotificacoes') },
            visible: visible
        },
        {
            nome: 'eSocial',
            desc: 'Consulte os eventos S2220 e S2240',
            img: Agendamento,
            svg: AgendamentoSvg,
            redirect: () => { history.push('/pages/eSocial') },
            visible: visibleSocial
        },
        {
          nome: 'Administrativo',
          desc: 'Consulte o cadastro de um funcionário',
          img: ConsultaAgendamento,
          svg: ConsultaAgendamentoSvg,
          redirect: () => {
            history.push('/pages/funcionarios')
          },
          visible: visible,
        },
        {
            nome: 'Financeiro',
            desc: 'Visualize suas informações financeiras',
            img: ConsultaAgendamento,
            svg: ConsultaAgendamentoSvg,
            redirect: () => { history.push('/pages/financeiro') },
            visible: visible && visibleFinanceiro,
        },
        {
          nome: 'Relatórios',
          desc: 'Geração de relatórios',
          img: ConsultaAgendamento,
          svg: ConsultaAgendamentoSvg,
          redirect: () => { history.push('/pages/relatorios') },
          visible: visible,
      }
    ];

    return (
        <>
            <Grid container>
            {
                data.map(element => (
                    <>
                    {element.visible ?
                        <Grid item xs={6} sm={6} style={{maxWidth: '475px', margin: '0 auto'}}>
                            <div style={{ display: 'inline-block', padding: '15px', verticalAlign: 'bottom', cursor: 'pointer' }}>
                                <div className="inicio" style={{ border: '1px solid #b8b8b8', borderRadius: '10px' }} onClick={element.redirect}>
                                    <div style={{ maxWidth: '100%' }}>
                                        <img alt="" src={element.svg} style={{ height: '80px', position: 'absolute', marginTop: '55px', marginLeft: '55px' }} />
                                        <img alt="" src={element.img} style={{ borderRadius: '10px 10px 0px 0px ', width: '450px' }} />
                                    </div>
                                    <div style={{ minHeight: 150, width: 450, background: '#fff', borderRadius: '0px 0px 10px 10px', color: '#969696' }}>
                                        <h1 style={{ padding: '15px' }}>{element.nome}</h1>
                                         <p style={{ padding: '15px', paddingTop: 0 }}>{element.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>  : ""}
                    </>
                ))}
            </Grid>
            <TransitionsModal openModal={open} closeModal={handleCloseModal} />

            <TransitionsModalSol openModal={openModalSol} closeModal={handleCloseModalSol} />
        </>
    );
}
