import { Control, Controller } from "react-hook-form";
import NumberFormat, {
  FormatInputValueFunction,
  NumberFormatValues,
} from "react-number-format";
import { TextField } from "@material-ui/core";

export default function Currency({
  control,
  name,
  label,
  rules,
  error,
}: {
  control: Control<Record<string, any>>;
  name: string;
  label: string;
  rules: any;
  error: any;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          style={{ width: "100%" }}
          customInput={TextField}
          label={label}
          prefix={"R$ "}
          variant="outlined"
          decimalSeparator=","
          thousandSeparator="."
          error={error}
          value={value}
          helperText={error && <span>{error.message}</span>}
          onValueChange={(v) => {
            //value without dollar signe
            onChange(v.floatValue);
          }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}

export function Masked({
  control,
  name,
  label,
  rules,
  error,
  format,
  onChangeItem,
  mask,
  disabled,
}: {
  onChangeItem?: (data: string) => void;
  control: Control<Record<string, any>>;
  format: string;
  mask: string;
  name: string;
  label: string;
  rules: any;
  error: any;
  disabled?: boolean;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          disabled={disabled}
          format={format}
          mask={mask}
          style={{ width: "100%" }}
          customInput={TextField}
          label={label}
          variant="outlined"
          decimalSeparator=","
          thousandSeparator="."
          error={error}
          value={value}
          helperText={error && <span>{error.message}</span>}
          onValueChange={(v) => {
            if (onChangeItem) onChangeItem(v.value);
            onChange(v.value);
          }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}

export function MaskedPro({
  control,
  name,
  label,
  rules,
  error,
  format,
  onChangeItem,
  mask,
  onBlur,
  forceInt = false,
  disabled = false,
  formatValue = false,
}: {
  control?: Control<Record<string, any>>;
  name: string;
  label: string;
  error: any;
  rules?: any;
  format?: string | FormatInputValueFunction | undefined;
  mask?: string;
  onChangeItem?: (values: NumberFormatValues) => void;
  onBlur?: (value: string) => void;
  forceInt?: boolean;
  disabled?: boolean;
  formatValue?: boolean;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          id={name}
          style={{ width: "100%" }}
          customInput={TextField}
          format={format}
          label={label}
          variant="outlined"
          mask={mask}
          error={error}
          value={value}
          helperText={error && <span>{error.message}</span>}
          onValueChange={(v) => {
            //value without dollar signe
            if (onChangeItem) onChangeItem(v);
            if (formatValue) {
              onChange(v.formattedValue);
              return;
            }
            onChange(forceInt ? v.floatValue : v.value);
          }}
          onBlur={(v) => {
            if (onBlur) onBlur(v.target.value.replace(/\D/g, ""));
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}
