import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Cookies from "js-cookie";
import { RootModel } from "../../redux/reducers";
import { clearSchedules } from "../../redux/actions/scheduleActions";

import { api } from "../../shared/services/api";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { formatUSDate } from "../../utils/formats";

const uploadFiles = async (files, atecodigo) => {
  const formData = new FormData();
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  files.forEach(async (file) => {
    formData.append("files", file.preview, file.name);
  });
  const request = await api();
  await request.post(
    `/agenda/agendamentos/${atecodigo}/arquivos`,
    formData,
    config
  );
};

export default function Schedules() {
  const { date, provider, schedule } = useSelector<RootModel, RootModel>(
    (state) => state
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<String | null>(null);
  const confirmCancelScheduleRef = useRef<boolean | null>(null);
  const confirmCancelScheduleRefMessage = useRef<string | null>(null);
  const history = useHistory();

  const [response, setResponse] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(clearSchedules());
    };
  }, [dispatch]);

  const cancelSchedule = async (ateCodigo: number) => {
    const req = await api();
    await req.delete(`/agenda/agendamentos/${ateCodigo}`, {
      params: {
        MOTIVO: `Agendamento cancelado e criado um novo na data ${moment(
          date
        ).format("YYYY-MM-DD")}`,
      },
    });
  };

  useEffect(() => {
    const saveSchedule = async () => {
      if (!schedule.length) {
        return history.push("/pages");
      }

      if (schedule?.length > 1) {
        toast.error(
          "Ocorreu um erro ao tentar finalizar o agendamento. Entre em contato com nosso Suporte"
        );
        return;
      }
      try {
        let agendamento = [];
        schedule.forEach(async (element) => {
          if (!element.Employee.ESOCCATEGCODIGO) {
            element.Employee.ESOCCATEGCODIGO = null;
          }

          agendamento = [
            ...agendamento,
            {
              FUNCIONARIO: element.Employee,
              ATEDATA: moment(date).format("YYYY-MM-DD"),
              ATEHORA: element.Time.substring(0, 8),
              ATEDATAHORA: moment(date).format("YYYY-MM-DD"),
              EXAMES: element.Exam,
              ATETIPOEXAME: element.ExamType.CODIGO,
              PROCODIGO: provider.PROCODIGO,
              TIPO: provider.TIPO,
              ATEOBSERVACAO: element.Observation,
              SOLICITACAO: element.Solicitation,
              FILES: element.files,
              NOTIFICAFUNCIONARIO: element.Employee.NOTIFICAFUNCIONARIO
            },
          ];
        });
        try {
          if (schedule[0].CancelPreviousAttend) {
            confirmCancelScheduleRef.current = true;
            confirmCancelScheduleRefMessage.current = `Agendamento da data ${schedule[0].CancelPreviousAttendDate} cancelado com sucesso`;
            await cancelSchedule(schedule[0].CancelPreviousAttend);
          }
        } catch (err) {
          confirmCancelScheduleRef.current = false;
          confirmCancelScheduleRefMessage.current = err.response?.data?.message || `Não foi possível cancelar o agendamento da data ${schedule[0].CancelPreviousAttendDate}`
        }
        const req = await api();

        const response = await req.post("/agenda/agendamentos", agendamento);
        const timeRegistered = Cookies.get("@PortalWEB:timeSelected") ? JSON.parse(Cookies.get("@PortalWEB:timeSelected")) : null;
        (
          async () => {
            try {
              const instance = await api()
              await instance.post("/agenda/finaliza-agendamentos", {
                ...timeRegistered,
                proData: formatUSDate(timeRegistered.proData).toISOString().split("T")[0]
              })
              Cookies.remove("@PortalWEB:timeSelected")
            } catch (e) {
              toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
            }
          }
        )()
        const files = await Promise.all(
          agendamento[0].FILES.map(async ({ file }) => {
            return {
              name: file.name,
              preview: file,
            };
          })
        );
        try {
          await uploadFiles(files, response.data[0].ATECODIGO);
        } catch (err) {
          setError(err.response?.data?.message);
        }
        setResponse(response.data);
      } catch (err) {
        setError(err.response?.data?.message);
        return;
      } finally {
        setLoading(false);
      }
    };

    saveSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <br />
        <br />
        <CircularProgress />
        <br />
        <Typography variant="h2">Finalizando... </Typography>
        <br />
        <br />
        <Typography variant="h3">progresso: 0/{schedule?.length}</Typography>
      </div>
    );

  if (error) {
    return (
      <>
        <Typography variant="h3">
          {"Falha ao efetuar os agendamentos:"}
        </Typography>
        <Typography variant="h6">{error}</Typography>
      </>
    );
  }

  return (
    <>
      {schedule && schedule.length && schedule[0].CancelPreviousAttend && (
        <div style={{ marginBottom: "25px" }}>
          <Alert
            severity={confirmCancelScheduleRef.current ? "success" : "error"}
          >
            {confirmCancelScheduleRefMessage.current}
          </Alert>
        </div>
      )}
      <Paper style={{ width: 1000 }}>
        {response.map((item) => {
          return item.MENSAGEM ? (
            <Alert style={{ margin: "20px 0px" }} severity="warning">
              {item.MENSAGEM}
            </Alert>
          ) : null;
        })}
        <Alert style={{ margin: "20px 0px" }} severity="info">
          A guia serve para visualização e conferência de dados, não há necessidade de impressão.
        </Alert>
        <Typography variant="h5" style={{ padding: 20 }}>
          Agendamento realizado com sucesso!
        </Typography>
        <Divider style={{ width: "100%" }} />
        <List title="Guias">
          {response.map((item) => (
            item.ARQUIVOGUIA ?
              <ListItem
                onClick={() => {
                  window.open(`${item.ARQUIVOGUIA}`);
                }}
                button
              >
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={item.ARQUIVONOME} />
              </ListItem>
              : null
          ))}
        </List>
      </Paper>
    </>
  );
}
