import { useEffect, useLayoutEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import DefaultLayout from "./shared/Layout";
import Routes from "./shared/Layout/Default/routes/Routes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/Login";
import termosUso from "./views/termosUso";
import { LayoutProvider } from "./hooks/auth";
import ThemeContext from "./contexts/theme";
import { AccessProvider } from "./contexts/access";
import useNovaSenha from "./views/novaSenha";
import CacheProvider from "./contexts/cache";
import { getInformations } from "./utils/getInformations";

require("dotenv").config();

function App(props) {
  useEffect(() => {
    Cookies.remove("@PortalWEB:Cache");
  }, []);

  useLayoutEffect(() => {
    const base = getInformations(window.location.host)
    // Set the document title
    document.title = base.name;

    // Set the favicon
    const favicon = document.getElementById('favicon');
    
    if (favicon) {
      //@ts-ignore
      favicon.href = base.image
    }
  }, []);

  return (
    <Provider store={store}>
      <AccessProvider>
        <LayoutProvider>
          <CssBaseline />
          <ThemeContext>
            <CacheProvider>
              <BrowserRouter>
                <Switch>
                  <Route path="/" exact={true} component={Login} />
                  <Route path="/termos" exact={true} component={termosUso} />
                  <Route
                    path="/redefinirSenha/:token"
                    exact={true}
                    component={useNovaSenha}
                  />
                  <DefaultLayout>
                    <Route path="/pages" component={Routes} />
                    <ToastContainer autoClose={3000} />
                  </DefaultLayout>
                </Switch>
              </BrowserRouter>
            </CacheProvider>
          </ThemeContext>
        </LayoutProvider>
      </AccessProvider>
    </Provider>
  );
}

export default App;
