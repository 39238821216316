import React from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import FormBody from "./Body";
import { SchemaSection } from "./model";

const convertArrayToObject = (array: SchemaSection[], key: any) => {
  const initialValue = {};
  var content = {};
  array.map((itemC) =>
    itemC.content.reduce((obj: any, item) => {
      content = {
        ...content,
        ...obj,
        [item[key]]: item.customDefValue || "",
      };

      return content;
    }, initialValue)
  );

  return content;
};

function Form<T>({
  defaultValues,
  onSubmit,
  schema,
  buttons,
  title,
  classForm,
  classBody,
  spacing,
}: {
  defaultValues?: any;
  onSubmit: SubmitHandler<T>;
  schema: SchemaSection<T>[];
  buttons: any;
  title?: any;
  classForm?: any;
  classBody?: any;
  spacing?: number | string;
}) {
  defaultValues = { ...convertArrayToObject(schema, "name"), ...defaultValues };

  const methods = useForm({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <form
        data-testid="form"
        className={classForm}
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormBody
          // @ts-ignore
          className={classBody}
          schema={schema}
          spacing={spacing}
        />
        {buttons(methods)}
      </form>
    </FormProvider>
  );
}

export default Form;
