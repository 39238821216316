import { format, isValid } from "date-fns";
import { Chip } from "@material-ui/core";
import { format as formatTZ } from "date-fns-tz";

export const formatTipoExame = (tipo: number) => {
  let name = "Não reconhecido";

  switch (tipo) {
    case 1:
      name = "Admissional";
      break;
    case 2:
      name = "Periódico";
      break;
    case 3:
      name = "Demissional";
      break;
    case 4:
      name = "Mudança de Risco";
      break;
    case 5:
      name = "Retorno ao Trabalho";
      break;
  }
  return <Chip size="small" variant="outlined" label={name} color="default" />;
};

// export const formatInvalidDate = (value: string) => {
//   const [year, month, day, ...rest] = value.split('-');
//   return day.split("T")[0] + '/' + month + '/' + year;
// }

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export const formatDate = (value: string) => {
  try {
    if (value && isValid(new Date(value))) {
      return formatTZ(convertDateToUTC(new Date(value)), "dd/MM/yyyy", {
        timeZone: "America/Sao_Paulo",
      });
    }
    return "";
  } catch (error) {
    return "Data inválida";
  }
};

export const formatCPFCNPJ = (inputValue: string) => {
  if (!inputValue) {
    return "";
  }

  if (inputValue.length > 14)
    return inputValue
      .slice(0, -1)
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

  if (inputValue.length === 11) {
    return inputValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (inputValue.length >= 14) {
    return inputValue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return inputValue;
};

export const formatUSDate = (dateString: string) => {
  // Dividir a string nos componentes de data
  const [month, day, year] = dateString.split('/').map(Number);

  // Criar um objeto Date
  const date = new Date(year, month - 1, day)

  return date
}

export function removerAcentos(palavra: string) {
  return palavra?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
}