import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

import { api } from "../../shared/services/api";
import { useSetepper } from "./Stepper";
import { ModalAlertRetornoAoTrabalho } from "./components/ModalAlertRetornoAoTrabalho";
import { TipoExameEnum, useSchedule } from ".";

export interface ExamTypeDomain {
  CODIGO: string;
  DESCRICAO: string;
  MUDARFUNCAO: boolean;
}

export default function TipoExame() {
  const [exam, setExam] = useState<ExamTypeDomain[] | null>();
  const { setSchedule } = useSchedule();
  const { setActiveStep } = useSetepper();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const reqTypeExam = async () => {
      const req = await api();
      await req.get<ExamTypeDomain[]>(`/agenda/exames`).then((response) => {
        setExam(response.data);
      });
    };

    reqTypeExam();
  }, []);

  if (!exam) return <div>Carregando...</div>;

  const onClose = (_event: object, reason: string | undefined) => {
    if (reason === "backdropClick") {
      toast.warning(
        "Clique em CONFIRMAR E CONTINUAR pra continuar o agendamento"
      );
      return;
    }
    setOpen(null);
    setActiveStep(1);
  };

  return (
    <div>
      {open && <ModalAlertRetornoAoTrabalho open={open} onClose={onClose} />}
      {exam.map((itemE, index) => (
        <Button
          key={index}
          variant="outlined"
          color="primary"
          onClick={() => {
            //@ts-ignore
            setSchedule({
              ExamType: itemE,
            });

            if (Number(itemE.CODIGO) === TipoExameEnum.RetornoAoTrabalho) {
              setOpen(true);
            } else {
              setActiveStep(1);
            }
          }}
          style={{ margin: "20px 10px" }}
        >
          {itemE.DESCRICAO}
        </Button>
      ))}
    </div>
  );
}
