/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Crud } from "../../shared";
import { api } from "../../shared/services/api";
import {
  Backdrop,
  CircularProgress,
  Divider,
  Fade,
  makeStyles,
  Modal,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import LoadingButton from "../../shared/Button/LoadingButton";
import { useLayout } from "../../hooks/auth";

import { formatTipoExame, formatDate } from "../../utils/formats";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  anchor: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function TableAnexos({ id, feacodigo }) {
  const [data, setData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(
        `/funcionarios/funcionarios/${id}/exameClinico/${feacodigo}/anexos`
      );
      if (res.data.length) {
        setData(res.data);
    } else {
        const { data } = await req.get(`/funcionarios/funcionarios/${id}/exameClinico/${feacodigo}/exames-pendentes`);
            console.log(data)
            setPendingData(data)
            
        }
        setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feacodigo]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  if (data.length === 0 && !isLoading && pendingData.length === 0) {
    return (
      <p style={{ textAlign: "center", fontSize: "20px" }}>
        Este exame não possui anexos
      </p>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {data.length > 0 && <TableCell>Nome do arquivo</TableCell>}
            {pendingData.length > 0 && (
              <TableCell>
                Os exames abaixo ainda não foi recebido o resultado do parceiro.
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingData.length > 0 && (
            <>
              {pendingData.map((arquivo) => (
                <TableRow key={arquivo.ARQGUID}>
                  <TableCell>{arquivo.EXANOMECOMERCIAL}</TableCell>
                </TableRow>
              ))}
            </>
          )}
          {data.map((arquivo) => (
            <TableRow key={arquivo.ARQGUID}>
              <TableCell>
                <a
                  className={classes.anchor}
                  onClick={() => {
                    window.open(arquivo.URL);
                  }}
                >
                  {arquivo.ARQNOME}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ModalAnexos({ open, closeItem, body }) {
  const classes = useStyles();
  const { colorPrimary } = useLayout();

  return (
    <Modal
      open={open}
      onClose={closeItem}
      closeAfterTransition
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2
            style={{
              width: "600px",
              color: colorPrimary,
              marginBottom: "15px",
            }}
          >
            Anexos
          </h2>
          <Divider
            color="primary"
            style={{ width: "111%", marginLeft: "-34px", marginBottom: "35px" }}
          />
          {body()}
        </div>
      </Fade>
    </Modal>
  );
}

export default function ExamesClinicos() {
  const params = useParams<{ id: string }>();
  const [data, setData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [feacodigo, setFeacodigo] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(
          `/clinico/funcionarios/${params.id}/exames/clinicos`
        );
        setData(res.data);
        setIsLoading(false);
      } catch (err) {}
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  if (data.length > 0) {
    const fieldsContent = [
      {
        label: "Tipo Exame",
        content: "FEATIPOEXAME",
        formatFun: formatTipoExame,
      },
      {
        label: "Prestador",
        content: "PRONOME",
      },
      {
        label: "Data de cadastro",
        content: "FEADATA",
        formatFun: formatDate,
      },
      {
        label: "Data de realização",
        content: "FEADATAREALIZADO",
        formatFun: formatDate,
      },
      {
        content: "FEACODIGO",
        button: (feacodigo) => {
          return (
            <LoadingButton
              variant="contained"
              color="primary"
              type="button"
              style={{ marginBottom: "10px", width: "120px" }}
              onClick={() => {
                setFeacodigo(feacodigo);
                setOpenModal(true);
              }}
            >
              Ver anexos
            </LoadingButton>
          );
        },
      },
    ];
    return (
      <>
        <Crud<any> content={data} schema={fieldsContent} />
        <ModalAnexos
          open={openModal}
          closeItem={() => setOpenModal(false)}
          body={() => <TableAnexos id={params.id} feacodigo={feacodigo} />}
        />
      </>
    );
  }
  return (
    <h3 style={{ textAlign: "center" }}>
      O funcionário não possui exames clínicos
    </h3>
  );
}
